<template>
  <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-space-between">
      <div style="width: 150px;">
        <v-select
          v-model="perPage"
          label="Total visible"
          :items="listPerPage"
          @change="resetCurrentPage"
          dense
        />
      </div>
      <div>
        <v-btn
          @click="getOrders"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
        <v-btn
          @click="createOrder"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          Pedido
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="orders"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <!-- <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              @click="openDetails(item)"
            >
              <v-icon left>mdi-menu</v-icon>
              <small>Detalles</small>
            </v-list-item>
          </v-list>
        </v-menu>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <v-progress-circular
          v-if="orderId === item.id && loadingUpdate"
          indeterminate
          color="primary"
          size="24"
        />
        <v-menu offset-y v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="item.status.name === 'PENDING'"
              link
              @click="actionsBuy(item, 'FAST_BUY')"
            >
              <v-list-item-title>
                <v-icon left>mdi-transfer-left</v-icon>
                Recibir pedido
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="item.status.name === 'PENDING'"
              link
              @click="editOrder(item)"
            >
              <v-list-item-title>
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="item.status.name === 'PENDING'"
              link
              @click="actionsBuy(item, 'CANCEL')"
            >
              <v-list-item-title>
                <v-icon left>mdi-cancel</v-icon>
                Cancelar
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              link
            >
              <v-list-item-title>
                <v-icon left>mdi-eye</v-icon>
                Ver
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="getReport(item.id)"
            >
              <v-list-item-title>
                <v-icon left>mdi-printer</v-icon>
                Imprimir
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.origin="{ item }">
        <div>
          <v-icon left small>mdi-account-outline</v-icon>
          <small> {{ item.buy_contact.name }} {{ item.buy_contact.last_name }} </small>
        </div>
        <div>
          <v-icon left small>mdi-card-account-details-outline</v-icon>
          <small> {{ item.buy_contact.contactable.nit }} </small>
        </div>
        <div>
          <v-icon left small>mdi-clock-outline</v-icon>
          <small>
            {{ item.buy_contact.contactable.supplier.processing_time }} {{ item.buy_contact.contactable.supplier.period }}
          </small>
        </div>
      </template>
      <template v-slot:item.destiny="{ item }">
        <Destiny
          :detail="item.destiny"
          type-detail="warehouse"
        />
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="colors[item.status.name]"
          x-small
          label
          outlined
        >
          {{ translators[item.status.name] }}
        </v-chip>
      </template>
      <template v-slot:item.order_num="{ item }">
        <b> {{ item.order_num }} </b>
      </template>
      <template v-slot:item.items="{ item }">
        <TScroll
          :height="60"
          :unaffectedRows="1"
          :rows="3"
        >
          <TTruncate
            v-for="sku in item.items"
            :key="`sku-item-${sku.id}`"
            :text="`${sku.sku.sku} x ${sku.quantity}`"
            :title="`${sku.sku.sku} - ${sku.sku.product.name}`"
            :width="100"
            small
          />
        </TScroll>
        <small class="d-flex justify-center">{{ item.items.length }} items</small>
      </template>
      <template v-slot:item.total="{ item }">
        <b class="success--text"> Bs. {{ item.total }} </b>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
    <FramePdf
      :fileUrl="fileUrl"
    />
  </div>
</template>

<script>
//components
import Destiny from './OrderDO';
import TTruncate from '@/components/commons/tiny/TTruncate';
import TScroll from '@/components/commons/tiny/TScroll';
// services
import OrderServices from '@/store/services/warehouse/OrderServices';
import StatusServices from '@/store/services/general/StatusServices';
// helpers
import { mapGetters } from 'vuex';
import ListMixin from '@/mixins/commons/ListMixin';
import { WAREHOUSE_ICON, STATUS_COLOR, STATUS_TRANSLATOR } from '@/constants';
import FramePdf from '../../general/reports/FramePdf';

export default {
  mixins: [ListMixin],
  components: {
    TTruncate,
    Destiny,
    TScroll,
    FramePdf
  },
  data() {
    return {
      loading: false,
      orders: [],

      icons: WAREHOUSE_ICON,
      colors: STATUS_COLOR,
      translators: STATUS_TRANSLATOR,

      orderId: -1,
      loadingUpdate: false,
      fileUrl: null,
      data: {}
    };
  },
  created() {
    this.getOrders();
    this.getStatuses();
  },
  methods: {
    async getReport(order) {
      try {
        console.log(order)
        this.loading = true
        let response = await OrderServices.file(this.user.archon.id, order, {
          params: this.data,
          responseType: 'blob',
          Accept: 'application/pdf',
        })
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        this.fileUrl = url
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    editOrder(order) {
      this.$router.push({
        name: 'orderBuyEdit',
        params: {
          orderId: order.id,
        },
      });
    },
    async getStatuses() {
      try {
        const response = await StatusServices.index(this.user.archon.id);
        this.statuses = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatus(name) {
      const index = this.statuses.findIndex(status => status.name === name);
      return this.statuses[index];
    },
    openDetails(item) {
      this.showDetails = true;
      this.currentSku = item;
    },
    async getOrders() {
      try {
        const params = {
          search_type: ['BUY'],
          page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'items.sku.product,destiny,status,buyContact.contactable.supplier',
        };
        this.loading = true;
        const response = await OrderServices.index(this.user.archon.id, params);
        this.orders = response.data.data;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.total = response.data.total;
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    createOrder() {
      this.$router.push({
        name: 'orderBuyCreate',
      });
    },
    // list
    resetCurrentPage() {
      if (this.currentPage === 1) this.getOrders();
      else this.currentPage = 1;
    },
    async actionsBuy(order, statusAction) {
      try {
        this.loadingUpdate = true;
        this.orderId = order.id;
        // TODO here run loaind specific
        const index = this.orders.findIndex(ord => ord.id === order.id);
        const status = this.getStatus(statusAction);
        let body = Object.assign({}, order);
        body.status_id = status.id;
        delete body.items;
        await OrderServices.update(this.user.archon.id, order.id, body);
        this.orders[index].status_id = status.id;
        this.orders[index].status = status;
        this.$notify({
          group: 'petition',
          type: 'success',
          title: 'Pedido',
          text: 'Pedido ha sido recibido y inventario fue agregado al warehouse',
        });
        this.orderId = -1;
        this.loadingUpdate = false;
      } catch (error) {
        this.orderId = -1;
        this.loadingUpdate = false;
        console.log(error);
        this.$notify({
          group: 'petition',
          type: 'error',
          title: 'Pedido',
          text: `Pedido ${order.order_num} no se pudo guardar`,
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Orden #',
          sortable: false,
          value: 'order_num',
        },
        { text: 'Instrucciones', value: 'instructions', sortable: false, align: 'center' },
        { text: 'Origen', value: 'origin', sortable: false },
        { text: 'Destino', value: 'destiny', sortable: false },
        { text: 'Estado', value: 'status', sortable: false },
        { text: 'Items', value: 'items', sortable: false },
        { text: 'Total', value: 'total', sortable: false },
      ];
    },
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },
}
</script>